<template>
    <div class="Exercisetesting clearfix">
        <div class="countdown">
            <div class="clearfix" style="width:100%">
                <div class="div_titles fl">推演测试</div>
                <div class="count_span fl" v-show="countNum==1">
                    <span>{{count.name}}倒计时：</span>
                    <span class="timer_s">{{timer}}</span>
                </div>
                <button class="count_but fr" @click="submit()" v-show="form.leader==true && searchVal<8">提交</button>
            </div>
        </div>
        <div class="Exercise_div">
            <div class="se_div_top">
                <p class="ts_p_one">
                    <i class="el-icon-warning-outline"></i>
                    <strong>注意事项</strong>
                </p>
                <p class="ts_p_two">
                    <span>该板块以组为单位进行合作，需要组长分配角色，组织拍摄一个小视频，拍摄内容要求针对某一个场景进行演绎。</span>
                </p>
                <p class="ts_p_two">
                    <span>该板块组员无法填写上传，最终视频由组长账号进行上传。</span>
                </p>
            </div>
            <div class="se_div_bottom">
                <div class="tui_right fl">
                    <div class="upload_div" 
                        v-show="forms.p1.value=='' && form.leader==true || forms.p1.value==null &&form.leader==true || forms.p1.value=='null'&& form.leader==true " v-loading="loading">
                            <el-upload
                                class="upload-demo"
                                drag
                                :action="this.$store.state.uploads"
                                accept=".avi, .mp4,"
                                :show-file-list="false"
                                :disabled="form.leader==true?false:true"
                                :on-success="handleVideoSuccess"
                                :on-progress="handlePreview"
                                :before-upload="beforeAvatarUpload">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                <div class="el-upload__tip" style="margin-top:0px">只能上传avi/mp4文件</div>
                            </el-upload>
                    </div>
                    <div v-show="forms.p1.value!='' && forms.p1.value!='null' && forms.p1.value!=null" class="cds_video">
                        <video-player
                            class="video-player vjs-custom-skin"
                            ref="videoPlayer"
                            :playsinline="true"
                            :options="playerOptions"
                            @play="onPlayerPlay($event)"
                            @pause="onPlayerPause($event)"
                            @ended="onPlayerEnded($event)"
                        ></video-player>
                        <div class="button_div" v-show="form.leader==true">
                            <el-button type="primary" plain size="small" @click="emptys()">重新上传</el-button>
                        </div>
                    </div>
                    <div class="img_div" v-show="forms.p1.value=='' && form.leader!=true">
                        <img src="../../assets/img/tuiyanceshi.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
export default {
    props: ['searchVals'],
    components: {
        videoPlayer
    },
    data() {
        return {
            searchVal:0,
            timer:'',
            countNum:0,
            count:{},
            value:'',
            loading:false,
            form:{},
            ruleForm:{
                role1:'',
                role2:'',
                role3:'',
                role4:'',
                role5:''
            },
            forms:{
                rule:{
                    values:[
                        {}
                    ]
                },
                p1:{},
                p2:{
                    value:{
                        role1:{},
                        role2:{},
                        role3:{},
                        role4:{},
                        role5:{}
                    }
                },
                user:{
                    values:[]
                }
            },
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                controls: true,
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                width: "100%",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src: '' // url地址
                    }
                ],
                poster:'', // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
        }
    },
    methods: {
        getapp(){
            this.searchVal = this.searchVals
            this.axios.StuCprojectgets7({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    this.playerOptions.sources[0].src = this.forms.p1.value;
                    var str = this.forms.resource
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        },
        // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' && res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        clearTimeout(this.timeouts)
                        if(this.count.state==1){
                            this.countDown()
                            this.timeoutss = setTimeout(this.down,5000)
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                    // this.$message.error(res.data.message)
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
                // setTimeout(this.down,5000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        // 提交
        submit(){
            this.axios.submits7({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                    this.$message.success('提交成功')
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
         // 清空数据
        emptys(){
            this.$confirm('重新上传后将会删除当前视频', '确定重新上传', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.StuCprojectdels7({
                    id:this.$store.state.cla_id,
                    token:this.$store.state.token,
                    node:'p1',
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        handlePreview(){
            this.loading = true
        },
        //上传视频成功后
        handleVideoSuccess(res, file){
            this.axios.StuCprojectsets7({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
                node:'p1',
                value:res.data
            }).then(res=>{
                if(res.data.code==0){
                    this.loading = false
                    this.$message.success('上传成功')
                    this.getapp()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        //验证类型
        beforeAvatarUpload(file) {
            const isimgtype = file.type;
            if (isimgtype === "video/avi" || isimgtype === "video/quicktime" || isimgtype === "video/mp4") {
            } else {
                this.$message.error("上传文件格式不匹配,请重新选择!");
                return false;
            }
        },
        // 播放回调
        onPlayerPlay(player) {
            console.log("player play!", player);
        },
        // 暂停回调
        onPlayerPause(player) {
            console.log("player pause!", player);
        },
        // 视频播完回调
        onPlayerEnded($event) {
            console.log(player);
        }
    },
    beforeDestroy () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        clearInterval(that.scont);
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearInterval(that.scont);
        clearTimeout(that.timeoutss)
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    mounted() {
        this.getapp()
        this.down()
        let that = this;
        that.scont = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        },5000)
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Exercisetesting.css';
</style>
<style>
.upload_div .el-upload-dragger{
    background: #F3F1F2;
    border-radius: 5px;
    height: 220px;
}
.upload_div .el-upload-dragger .el-icon-upload{
    margin-top: 60px;
}
.el-upload__tip{
    line-height: 24px;
}
.cds_video .video-js{
    height: 100%!important;
}
.cds_video .video-js .vjs-mute-control{
    padding-left: 0;
}
.cds_video .video-js .vjs-control{
    width: 2.5em;
}
</style>